import React, { useState } from 'react';
import { Link } from 'gatsby';

const Navbar = () => {
	const [active, setActive] = useState(false);

	const toggleHamburger = () => {
		setActive(!active);
	};

	return (
		<nav className={`navbar is-transparent ${active ? 'is-active' : ''}`}>
			<div className="container">
				<div className="navbar-brand">
					<div
						className={`navbar-burger burger ${active ? 'is-active' : ''}`}
						data-target="navMenu"
						role="menuitem"
						tabIndex="0"
						onClick={() => toggleHamburger()}
						onKeyPress={() => toggleHamburger()}
					>
						<span />
						<span />
						<span />
					</div>
				</div>
				<div
					id="navMenu"
					className={`navbar-menu ${active ? 'is-active' : ''}`}
				>
					<div className="navbar-end has-text-centered">
						<Link
							className="navbar-item has-text-weight-bold"
							to="/"
							activeClassName="is-active"
						>
							Hem
						</Link>
						<Link
							className="navbar-item has-text-weight-bold"
							to="/artiklar"
							activeClassName="is-active"
						>
							Artiklar
						</Link>
						<Link
							className="navbar-item has-text-weight-bold"
							to="/kontakt"
							activeClassName="is-active"
						>
							Kontakta oss
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
