import React from 'react';

import logo from '../assets/img/logo.png';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="content has-text-centered">
				<img src={logo} alt="ADSS" style={{ height: '4rem' }} />
				<p>Copyright © {new Date().getFullYear()}</p>
			</div>
		</footer>
	);
};

export default Footer;
