import React from 'react';

import NavBar from './Navbar';
import hero from '../assets/img/hero.jpg';

const Hero = ({ img, title }) => {
	const heroImg = img || hero;
	return (
		<>
			<NavBar />

			<section
				className="hero is-info is-medium is-bold"
				style={{ backgroundImage: `url(${heroImg})` }}
			>
				{title && <h1 className="hero__content">{title}</h1>}
			</section>
		</>
	);
};

export default Hero;
